import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import Carousel from '../utils/Carousel';
import CookieConsent from 'react-cookie-consent';
import useWindowSize from '../utils/useWindowsSize';

const Homepage = () => {
    const { t } = useTranslation();
    const [headerCss] = useState<string>('');
    const { width } = useWindowSize();

    useEffect(() => {
        document.body.classList.add('landing-page');
        return () => {
            document.body.classList.remove('landing-page');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('Tipsarevic Tennis Academy')}`}</title>
            </Helmet>
            <CookieConsent
                buttonText={t('dugmeSlazem')}
                buttonWrapperClasses={width < 455 ? 'cookiesButton' : ''}
                buttonStyle={{ backgroundColor: '#995d47', fontSize: '13px' }}
                style={{ flexDirection: 'row' }}
            >
                {t('translateCookies')}
            </CookieConsent>
            <Header cssClass={headerCss} />
            <div className='wrapper'>
                <div className='carusel'>
                    <Carousel />
                </div>
                <div className='program program-one'>
                    <h3>
                        {' '}
                        <Link
                            to={`/${i18next.language}/${t(
                                'takmicarskiprogram',
                            )}`}
                        >
                            {t('Takmičarski program')}
                        </Link>
                    </h3>
                    <div className='line-effect'>
                        <span className='line'></span>
                        <span className='line-hover'></span>
                    </div>
                    <p className='subtitle'>{t('napredniProgram')}</p>
                </div>
                <div className='program program-two'>
                    <h3>
                        {' '}
                        <Link
                            to={`/${i18next.language}/${t('skolskiprogram')}`}
                        >
                            {t('Škola tenisa')}
                        </Link>
                    </h3>
                    <div className='line-effect'>
                        <span className='line'></span>
                        <span className='line-hover'></span>
                    </div>
                    <p className='subtitle'>{t('skolskiProgramTitle')}</p>
                </div>
                <div className='program program-three'>
                    <h3>
                        <Link
                            to={`/${i18next.language}/${t(
                                'rekreativniprogram',
                            )}`}
                        >
                            {t('Rekreativni tenis')}
                        </Link>
                    </h3>
                    <div className='line-effect'>
                        <span className='line'></span>
                        <span className='line-hover'></span>
                    </div>
                    <p className='subtitle'>{t('rekreativniProgramTitle')}</p>
                </div>

                <div className='footer'>
                    <div className='landing-sponsors sponsors'>
                        <ul>
                            <li className='sponsor-three'>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.lacoste.com/'
                                >
                                    Lacoste
                                </a>
                            </li>
                            <li className='sponsor-two'>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://www.pfsinfo.org/'
                                >
                                    PFS
                                </a>
                            </li>
                            <li className='sponsor-one'>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://college-star.com/'
                                >
                                    Peugeot
                                </a>
                            </li>
                        </ul>
                    </div>
                    <p className='copyrights'>
                        All rights reserved by Tipsarevic tennis academy{' '}
                        <a
                            style={{ color: 'rgb(103, 103, 103' }}
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.swibs.xyz/'
                        >
                            Powered by SWIBS
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Homepage;
